import { Dispatch } from "redux";
import { postRequest, postRequest2, getRequest } from "../utils/http";
import * as T from "../actions/Types";
const api_domian = process.env.REACT_APP_API_HOST as string;

const getMessageJson = (FromGuid: string, ToGuid: string, Message: string, File?: File, fileName?: string) => {
    const msgobj = { FromGuid, ToGuid, Message, File, fileName };
    return msgobj;
}
export const StoreMsg = (msg: any, peerId: string) => async (dispatch: Dispatch) => {
    dispatch({ type: T.STORE_MSG, payload: { msg, peerId } });
}
/** FromGuid:6DB3707E-ED5B-4D38-BFC8-E58EFD3C8219
ToGuid:D9E7F49D-5E64-42D2-BC69-9C37B25EAD03
Message:Hi testing
//FileName:qr_code.png */
export const SaveChat = (params: any) => async (dispatch: Dispatch) => {
    postRequest(`${api_domian}/chat/SaveChat`, params)
        .then((resp: any) => {
            if (resp.status == 'success') {
                dispatch({ type: T.SAVE_MSG, payload: { msgId: resp.result, params } });
            } else {
                console.log('error in saving message');
            }
        }).catch(e => {
            console.log('save mesage error', params);
        });
}
export const SaveGuestChat = (params: any) => async (dispatch: Dispatch) => {
    postRequest(`${api_domian}/chat/SaveGuestChat`, params)
        .then((resp: any) => {
            if (resp.status == 'success') {
                dispatch({ type: T.SAVE_MSG, payload: { msgId: resp.result, params } });
            } else {
                console.log('error in saving message');
            }
        }).catch(e => {
            console.log('save mesage error', params);
        });
}
/** chatMessageId=8 */
export const UpdateAsSeen = (params: any, peerId: string) => async (dispatch: Dispatch) => {
    postRequest(`${api_domian}/chat/UpdateChatMessageAsSeen`, params)
        .then((resp: any) => {
            if (resp.status == 'success') {
                dispatch({ type: T.UPDATE_MSG, payload: { peerId } });
            }
        }).catch(e => {
            console.log('update message error', params);
        });
}
/**chatMessageId */
export const DeleteMessage = (empId: string, msgId: string, peerId: string) => async (dispatch: Dispatch) => {
    getRequest(`${api_domian}/chat/DeleteChatMessage?empGuid=${empId}&uuid=${msgId}`)
        .then((resp: any) => {
            if (resp.status == 'success') {
                dispatch({ type: T.DELETE_MSG, payload: { msgId, peerId } });
            }
        }).catch(e => {
            console.log('Delete message error', msgId);
        });
}
export const DeleteGuestMessage = (empId: string, msgId: string, peerId: string) => async (dispatch: Dispatch) => {
    getRequest(`${api_domian}/chat/DeleteChatMessage?empId=${empId}&uuid=${msgId}`)
        .then((resp: any) => {
            if (resp.status == 'success') {
                dispatch({ type: T.DELETE_MSG, payload: { msgId, peerId } });
            }
        }).catch(e => {
            console.log('Delete message error', msgId);
        });
}

/** chatMessageId=8, message: 'testing' */
export const UpdateMsgContent = (params: any) => async (dispatch: Dispatch) => {
    postRequest(`${api_domian}/chat/UpdateChatMessageContent`, params)
        .then((resp: any) => {
            if (resp.status == 'success') {
                dispatch({ type: T.EDIT_CONTENT_MSG, payload: { msgId: resp.result, params } });
            }
        }).catch(e => {
            console.log('update message error', params);
        });
}
/** emp1='id1', emp2:'id' */
export const GetUnseenUserMessagesCount = (empId1: string) => async (dispatch: Dispatch) => {
    getRequest(`${api_domian}/chat/GetUnseenUserMessagesCount?empGuid=${empId1}`)
        .then((resp: any) => {
            //console.log('unread counts', resp);
            const results: any = { 'IndTotal': 0, 'emps': [] };
            let emps: any = {};
            let indTotal = 0;
            if (resp.status == 'success' && resp.result.length > 0) {

                resp.result.map((e: any) => {
                    // results[e.FromEmployeeGuid] = e.Count;
                    emps = {};
                    emps.Id = e.FromEmployeeGuid;
                    emps.Name = e.EmpName;
                    emps.Count = e.Count;
                    results.emps.push(emps);
                    indTotal += e.Count;
                });

            }
            results.IndTotal = indTotal;
            dispatch({ type: T.GET_UNREAD_MSGS, payload: results });
        }).catch(e => {
            dispatch({ type: T.GET_UNREAD_MSGS_ERROR, error: { ...e } });
        });
}
/** emp1='id1', groupGuid:'id' */
export const GetUnseenGroupMessagesCount = (empId1: string) => async (dispatch: Dispatch) => {
    getRequest(`${api_domian}/chat/GetUnseenGroupMessagesCount?empGuid=${empId1}`)
        .then((resp: any) => {
            //console.log('unread group counts', resp);
            if (resp.status == 'success' && resp.result.length > 0) {
                const results: any = {};
                let groupTotal = 0;
                resp.result.map((e: any) => {
                    results[e.GroupGuid] = e.Count;
                    groupTotal += e.Count;
                });
                results.GroupTotal = groupTotal;
                dispatch({ type: T.GET_UNREAD_MSGS, payload: results });
            }else 
            dispatch({ type: T.GET_UNREAD_MSGS, payload: [] });
        }).catch(e => {
            dispatch({ type: T.GET_UNREAD_MSGS_ERROR, error: { ...e } });
        });
}
/**Get List of Read Messages - User2User, emp1, emp2, pageNumber */
export const GetMessageList = (empId1: string, empId2: string, pn: number) => async (dispatch: Dispatch) => {
    console.log("GetMessageList");
    getRequest(`${api_domian}/chat/GetMessagesListForUser?emp1=${empId1}&emp2=${empId2}&TimeStamp=${pn}`)
        .then((resp: any) => {
            console.log("GetMessageList SSSS");
            // console.log(resp);
            if (resp.status == 'success') {
                console.log("GetMessageList dispatch");
                dispatch({ type: T.GET_MSG_LIST, payload: { peerId: empId2, results: resp.result.length > 0 ? resp.result : [] } });
                console.log("GetMessageList dispatch 222");
            } else {
                console.log('no more messages');
            }
        }).catch(e => {
            dispatch({ type: T.GET_MSG_LIST_ERROR, error: { ...e } });
        });
}

/**Get List of Read Messages - User2User, emp1, emp2, pageNumber */
export const GetMessagesListForGroup = (empId1: string, groupGuid: string, pn: number, isGuest: boolean = false) => async (dispatch: Dispatch) => {

    if (isGuest) {
        getRequest(`${api_domian}/chat/GetGuestMessagesListForGroup?emp1=${empId1}&groupGuid=${groupGuid}&TimeStamp=${pn}`)
            .then((resp: any) => {
                // console.log(resp);
                if (resp.status == 'success') {``
                    dispatch({ type: T.GET_MSG_LIST, payload: { peerId: groupGuid, results: resp.result.length > 0 ? resp.result : [] } });
                }
            }).catch(e => {
                dispatch({ type: T.GET_MSG_LIST_ERROR, error: { ...e } });
            });
    } else {
        getRequest(`${api_domian}/chat/GetMessagesListForGroup?emp1=${empId1}&groupGuid=${groupGuid}&TimeStamp=${pn}`)
            .then((resp: any) => {
                // console.log(resp);
                if (resp.status == 'success' ) {
                    dispatch({ type: T.GET_MSG_LIST, payload: { peerId: groupGuid, results: resp.result.length > 0 ? resp.result : [] } });
                }
            }).catch(e => {
                dispatch({ type: T.GET_MSG_LIST_ERROR, error: { ...e } });
            });
    }
}

export const GetMessageHeaders = (empGuid: string) => async (dispatch: Dispatch) => {
    getRequest(`${api_domian}/chat/GetChatHeadersWithUnreadCount?empGuid=${empGuid}`)
        .then((resp: any) => {
            if (resp.status == 'success') {
                dispatch({ type: T.GET_CHAT_HEADERS, payload: resp.result });
            }
        }).catch(e => {
            dispatch({ type: T.GET_CHAT_HEADERS_ERROR, error: { ...e } });
        });
}

/** chatMessageId=8, message: 'testing' */
export const saveFilesForUser = (params: any) => async (dispatch: Dispatch) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    postRequest2(`${api_domian}/chat/UploadChatFiles`, params, config).then((resp: any) => {
        //console.log('file upload resp', resp);
        if (resp.status == 'success') {
            dispatch({ type: T.USER_FILES_UPLOADED, payload: resp.result });
        }
    }).catch(e => {
        console.log('update message error', e);
    });
}
export const afterSentFilesMessage = (params: any) => async (dispatch: Dispatch) => {
    dispatch({ type: T.USER_FILES_SENTMSG });
}
