import { useEffect, Suspense, lazy } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useClearCache } from 'react-clear-cache';
import store from "./store/Store";
import Toast from "./components/toast";
import Gloader, { Spinner } from "./components/Gloader";
import GuestRoute from "./utils/GuestRoute";
import PrivateRoute from "./utils/PrivateRoute";
import WindowFocusHandler from "./components/WindowsFocusHandler";
import IncomingCallDialog from "./pages/myoffice/IncomingCallDialog";
import "./App.css";
import GuestLayout from "./pages/classic/GuestLayout";
import PrivateLayout from "./pages/classic/PrivateLayout";

const Home = lazy(() => import("./pages"));
const Login = lazy(() => import("./pages/classic/signin"));
const Pod = lazy(() => import("./pages/dashboard/Pod"));
const User = lazy(() => import("./pages/hr/User"));
const CompanyInfo = lazy(() => import("./pages/settings/CompanyInfo"));
//const Conference = lazy(() => import("./pages/classic/MeetingRooms/Conference"));
const Conference = lazy(() => import("./pages/classic/conference"));
const Files = lazy(() => import("./pages/myoffice/Files"));
const Users = lazy(() => import("./pages/hr/Users"));
const HelpFeedBack = lazy(() => import("./pages/helpFeedback/helpFeedback"));
const Resources = lazy(() => import("./pages/classic/HelpAndResources"));
const Chat = lazy(() => import("./pages/chat/chat"));
const FeedBack = lazy(() => import("./pages/feedback/feedback"));
const MyProfile = lazy(() => import("./pages/classic/dashboard"));
const ResetPassword = lazy(() => import("./pages/resetPassword/ResetPassword"));
const Companies = lazy(() => import("./pages/hr/Companies"));
const Company = lazy(() => import("./pages/hr/Company"));
const HangoutRoom = lazy(() => import("./pages/rooms/HangoutRoom"));
const Produce = lazy(() => import("./pages/myoffice/Produce"));
const UserProfile = lazy(() => import("./pages/settings/UserProfile"));
//const Calendar = lazy(() => import("./pages/calendar/Calendar"));
const Calendar = lazy(() => import("./pages/classic/Calender"));

const EarnLearn = lazy(() => import("./pages/rooms/EarnLearn"));
const Learn = lazy(() => import("./pages/learn/learn"));
const Leadership = lazy(() => import("./pages/leadership/leadership"));
const Diversity = lazy(() => import("./pages/diversity/diversity"));
const Philanthropy = lazy(() => import("./pages/philanthropy/philanthropy"));
const SuteSelections = lazy(() => import("./pages/suteSelections/suteSelections"));
const SoundBathMeditations = lazy(() => import("./pages/soundBathMeditations/soundBathMeditations"));
const MentalHealth = lazy(() => import("./pages/mentalHealth/mentalHealth"));
const LifeBalance = lazy(() => import("./pages/lifeBalance/lifeBalance"));
const GuidedMeditations = lazy(() => import("./pages/guidedMeditations/guidedMeditations"));
const PersonalGrowth = lazy(() => import("./pages/personalGrowth/personalGrowth"));
const ForgetPassword = lazy(() => import('./pages/forgetPassword/ForgetPassword'));
const Checkins = lazy(() => import('./pages/myoffice/Checkins'));
const CheckinHistory = lazy(() => import("./pages/myoffice/CheckinHistory"));
const TeamCheckinHistory = lazy(() => import("./pages/myoffice/TeamCheckinHistory"));
const CompanyFiles = lazy(() => import("./pages/orgFiles/CompanyFiles"));
const ManageBulletinBoard = lazy(() => import("./pages/orgFiles/ManageBulletinBoard"));
const Admin = lazy(() => import("./pages/dashboard/Admin"));
const Groups = lazy(() => import("./pages/hr/Groups"));
const Assessment = lazy(() => import("./pages/wellness/assessment"));
const HealthTracking = lazy(() => import("./pages/wellness/healthTracking"));
const Information = lazy(() => import("./pages/wellness/information"));
const YourActivity = lazy(() => import("./pages/wellness/yourActivity"));
const SelfHelp = lazy(() => import("./pages/wellness/selfHelp"));
const BuddyChallenges = lazy(() => import("./pages/wellness/buddyChallenge"));
const happyHours = lazy(() => import("./pages/wellness/happyHours"));
const FitnessVideos = lazy(() => import("./pages/wellness/fitnessVideos"));
const chatroulette = lazy(() => import("./pages/wellness/chatroulette"));
const Challenges = lazy(() => import("./pages/wellness/Challenges"));
const Classes = lazy(() => import("./pages/wellness/Classes"));
const WellnessPartners = lazy(() => import("./pages/wellness/WellnessPartners"));
const Leaderboard = lazy(() => import("./pages/wellness/Leaderboard"));
const CuratedChallenges = lazy(() => import("./pages/wellness/CuratedChallenges"));
const challengesSteps = lazy(() => import("./pages/wellness/challengesSteps"));
const IncomingChatDialog = lazy(() => import("./pages/myoffice/incomingChat"));
const ChatBot = lazy(() => import("./pages/chat/chatBot"));
const myMeetings = lazy(() => import("./pages/myMeetings/myMeetings"));
const ChallengesMenu = lazy(() => import("./pages/hr/ChallengesMenu"));
const NewChallenges = lazy(() => import("./pages/hr/NewChallenges"));
const OngoingChallenges = lazy(() => import("./pages/hr/OngoingChallenges"));
const CompletedChallenges = lazy(() => import("./pages/hr/CompletedChallenges"));
const OngoingChallengesDetails = lazy(() => import("./pages/hr/OngoingChallengesDetails"));
const MettingRoom = lazy(() => import("./pages/rooms/MettingRoom"));
const CollaborationHub = lazy(() => import("./pages/collaborationHub/CollaborationHub"));
const NewChallengesList = lazy(() => import("./pages/hr/NewChallengesList"));
const InvitePeople = lazy(() => import("./pages/myoffice/InvitePeople"));
const registration = lazy(() => import("./pages/classic/register"));
const forgotpassword = lazy(() => import("./pages/classic/forgotpassword"));
const resetpassword = lazy(() => import("./pages/classic/resetpassword"));
const Dashboard = lazy(() => import("./pages/classic/dashboard"));
const Rooms = lazy(() => import("./pages/classic/Rooms"));

function Main() {
    const { isLatestVersion, emptyCacheStorage } = useClearCache();

    useEffect(() => {

        const NEWver = process.env.REACT_APP_VERSION as string;
        let OLDver = localStorage.getItem('SUTE_APP_VERSION') as string;

        if (!OLDver) {
            chacheClear(NEWver)
        }
        else {
            if (OLDver.trim() != NEWver.trim()) {
                chacheClear(NEWver)
            }

        }
        console.log(OLDver, NEWver);
        console.log("#TEST App");

    }, [])

    const chacheClear = (NEWver: any) => {
        emptyCacheStorage();
        localStorage.setItem('SUTE_APP_VERSION', NEWver);
    }

    return (

        <Provider store={store} stabilityCheck="never">
            <Router>
                <Suspense fallback={<Spinner />}>
                    <Routes>
                        <Route path="/" element={<GuestLayout> <GuestRoute component={Login} /> </GuestLayout>} />
                        <Route path="/login" element={<GuestLayout> <GuestRoute component={Login} /> </GuestLayout>} />
                        <Route path="/pod" element={<PrivateRoute component={Pod} />} />
                        <Route path="/admin" element={<PrivateRoute component={Admin} />} />
                        <Route path="/conference/:meetingPId" element={<GuestRoute component={Conference} />} />
                        <Route path="/customrm/:meetingPId" element={<GuestRoute component={MettingRoom} />} />
                        <Route path="/companyInfo" element={<PrivateRoute component={CompanyInfo} />} />
                        <Route path="/Induction" element={<PrivateRoute component={CompanyFiles} />} />
                        <Route path="/user" element={<PrivateRoute component={User} />} />
                        <Route path="/files" element={<PrivateRoute component={Files} />} />
                        <Route path="/help-feedback" element={<PrivateRoute component={HelpFeedBack} />} />
                        <Route path="/resources" element={<PrivateLayout><PrivateRoute component={Resources} /></PrivateLayout>} />
                        <Route path="/chat" element={<PrivateRoute component={Chat} />} />
                        <Route path="/feedback" element={<PrivateRoute component={FeedBack} />} />
                        <Route path="/my-profile" element={<PrivateLayout><PrivateRoute component={MyProfile} /></PrivateLayout>} />
                        <Route path="/hangoutroom" element={<PrivateRoute component={HangoutRoom} />} />
                        <Route path="/produce" element={<PrivateRoute component={Produce} />} />
                        <Route path="/checkins" element={<PrivateRoute component={Checkins} />} />
                        <Route path="/checkinhistory" element={<PrivateRoute component={CheckinHistory} />} />
                        <Route path="/teamcheckinhistory" element={<PrivateRoute component={TeamCheckinHistory} />} />
                        <Route path="/userProfile" element={<PrivateRoute component={MyProfile} />} />
                        <Route path="/calendar" element={<PrivateRoute component={Calendar} />} />
                        <Route path="/earn-learn" element={<PrivateRoute component={EarnLearn} />} />
                        <Route path="/learn" element={<PrivateRoute component={Learn} />} />
                        <Route path="/leadership" element={<PrivateRoute component={Leadership} />} />
                        <Route path="/diversity" element={<PrivateRoute component={Diversity} />} />
                        <Route path="/philanthropy" element={<PrivateRoute component={Philanthropy} />} />
                        <Route path="/life-balance" element={<PrivateRoute component={LifeBalance} />} />
                        <Route path="/users" element={<PrivateRoute component={Users} />} />
                        <Route path="/companies" element={<PrivateRoute component={Companies} />} />
                        <Route path="/company" element={<PrivateRoute component={Company} />} />
                        <Route path="/forget-password" element={<GuestLayout><GuestRoute component={forgotpassword} /></GuestLayout>} />
                        <Route path="/reset-password/:tokenId" element={<GuestLayout><GuestRoute component={resetpassword} /></GuestLayout>} />
                        <Route path="/sute-selections" element={<PrivateRoute component={SuteSelections} />} />
                        <Route path="/soundbath-meditations" element={<PrivateRoute component={SoundBathMeditations} />} />
                        <Route path="/mental-health" element={<PrivateRoute component={MentalHealth} />} />
                        <Route path="/guided-meditations" element={<PrivateRoute component={GuidedMeditations} />} />
                        <Route path="/personal-growth" element={<PrivateRoute component={PersonalGrowth} />} />
                        <Route path="/ManageBulletinBoard" element={<PrivateRoute component={ManageBulletinBoard} />} />
                        <Route path="/groups" element={<PrivateRoute component={Groups} />} />
                        <Route path="/AdminChallenges" element={<PrivateRoute component={ChallengesMenu} />} />
                        <Route path="/NewChallenges" element={<PrivateRoute component={NewChallenges} />} />
                        <Route path="/OngoingChallengesDetails/:orgGuidParam/:stepId" element={<PrivateRoute component={OngoingChallengesDetails} />} />
                        <Route path="/OngoingChallenges" element={<PrivateRoute component={OngoingChallenges} />} />
                        <Route path="/CompletedChallenges" element={<PrivateRoute component={CompletedChallenges} />} />
                        <Route path="/healthtracking" element={<PrivateRoute component={HealthTracking} />} />
                        <Route path="/information" element={<PrivateRoute component={Information} />} />
                        <Route path="/youractivity" element={<PrivateRoute component={YourActivity} />} />
                        <Route path="/selfhelp" element={<PrivateRoute component={SelfHelp} />} />
                        <Route path="/buddychallenges" element={<PrivateRoute component={BuddyChallenges} />} />
                        <Route path="/happyhours" element={<PrivateRoute component={happyHours} />} />
                        <Route path="/wellnessMedia" element={<PrivateRoute component={FitnessVideos} />} />
                        <Route path="/chatroulette" element={<PrivateRoute component={chatroulette} />} />
                        <Route path="/assessment" element={<PrivateRoute component={Assessment} />} />
                        <Route path="/challenges" element={<PrivateRoute component={Challenges} />} />
                        <Route path="/classes" element={<PrivateRoute component={Classes} />} />
                        <Route path="/wellnesspartners" element={<PrivateRoute component={WellnessPartners} />} />
                        <Route path="/leaderboard" element={<PrivateRoute component={Leaderboard} />} />
                        <Route path="/curatedchallenges" element={<PrivateRoute component={CuratedChallenges} />} />
                        <Route path="/challengesSteps" element={<PrivateRoute component={challengesSteps} />} />
                        <Route path="/myMeetings" element={<PrivateRoute component={myMeetings} />} />
                        <Route path="/collaboration-hub" element={<PrivateRoute component={CollaborationHub} />} />
                        <Route path="/Challenge" element={<PrivateRoute component={NewChallengesList} />} />
                        <Route path="/invitepeople" element={<PrivateRoute component={InvitePeople} />} />
                        <Route path="/registration" element={<GuestRoute component={registration} />} />
                        <Route path="/dashboard" element={<GuestRoute component={Dashboard} />} />
                        <Route path="/rooms" element={<PrivateLayout><PrivateRoute component={Rooms} /></PrivateLayout>} />

                    </Routes>
                </Suspense>
                <WindowFocusHandler />
                <IncomingCallDialog />
                <Gloader />
                <Toast />

            </Router>

        </Provider>
    );
}

export default Main;
